<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6" v-if="course">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{course.product.name}}
      </h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">
        {{certificate.date}}
      </p>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="sm:divide-y sm:divide-gray-200">
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            Certificate file
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
              <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div class="w-0 flex-1 flex items-center">
                  <PaperClipIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span class="ml-2 flex-1 w-0 truncate">
                    {{certificate.file.split('certificates/')[1]}}
                  </span>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <button @click="open(certificate.file)" class="font-medium text-indigo-600 hover:text-indigo-500">
                    Open
                  </button>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import { PaperClipIcon } from '@heroicons/vue/solid'
import certificates from "../../views/Certificates";

export default {
  components: {
    PaperClipIcon,
  },
  data() {
    return {
      course: null
    }
  },
  methods: {
    open(url) {
      window.open(process.env.VUE_APP_ROOT_API.split('api')[0] + url, '_blank').focus();
    }
  },
  props: ['certificate'],
  async mounted() {
    let course = await this.axios.get(`service/course/${this.certificate.listening.course}/`)
    this.course = course.data
  }
}
</script>