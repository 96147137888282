<template>
  <div class="pt-24 px-4 sm:px-24">
    <item v-for="(c, i) in certs" :key="i" class="mt-10" :certificate="c"/>
  </div>
</template>

<script>
import item from "../components/certificate/item";

export default {
  name: "Certificates",
  components: {
    item
  },
  data() {
    return {
      certs: []
    }
  },
  async mounted() {
    let {data} = await this.axios.get('service/certificates?ordering=-pk')
    this.certs = data
  }
}
</script>

<style scoped>

</style>